import * as React from "react"
import micro1 from "../../assets/images/conTrac1.jpg"
import micro2 from "../../assets/images/conTrac2.jpg"
import micro3 from "../../assets/images/n2.jpg"
import empowericon from "../../assets/images/icons/aboutarrow.svg"
import { Link } from "gatsby"
import MicroBox from "../roaster/microbox"

const Traceability = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="micro traceability consumerTraceability">
          <div className="content">
            <div className="title">
              <h2>{translate.t?.how_traceability_works}</h2>
              <Link
                className="paragraph-p3-link paragraph-p3"
                to={`${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/"
                }traceability/`}
              >
                <span>{translate.t?.view_more_traceability}</span>
                <img src={empowericon} alt="Empower" />
              </Link>
            </div>
            <div className="micro-box">
              <div className="micro-box-item">
                <MicroBox
                  img={micro1}
                  count="1"
                  heading={translate.t?.go_to_nearest_partner}
                  description={translate.t?.our_partners_guide_process}
                />
              </div>
              <div className="micro-box-item">
                <MicroBox
                  img={micro2}
                  count="2"
                  heading={translate.t?.scan_qr_code}
                  description={translate.t?.scan_qr_on_product}
                />
              </div>
              <div className="micro-box-item">
                <MicroBox
                  img={micro3}
                  count="3"
                  heading={translate.t?.connect_with_our_farmers}
                  description={translate.t?.our_exp_journey_shapes_coffee}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Traceability
