import * as React from "react"
import { Container } from "react-bootstrap"
import Link from "../link"
import coffeeLab from "../../assets/images/coffee-lab.gif"
import findOutlet from "../../assets/images/white-i-phone.svg"
import shop from "../../assets/images/shop.jpg"
import logo1 from "../../assets/images/logos/artboard-logo.svg"
import logo2 from "../../assets/images/logos/green-logo.svg"
import logo3 from "../../assets/images/logos/copper-flat-logo.svg"
import logo4 from "../../assets/images/logos/california-logo.svg"
import logo5 from "../../assets/images/logos/ama-logo.svg"

const Setbox = ({
  title,
  heading,
  description,
  btnText,
  btnLink,
  pageContext: { translate },
}) => (
  <div className="setbox">
    <h4>{title}</h4>
    <h5>{heading}</h5>
    <p>{description}</p>
    <Link to={btnLink}>
      <button className="btndefault greenbtn">{btnText}</button>
    </Link>
  </div>
)

const Offer = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="offer">
          <div className="greyBg">
            <Container>
              <div className="sectoinHeading">
                <h2>{translate.t?.what_platform_offers}</h2>
                <p>{translate.t?.eraofwe_provides_edu_beans}</p>
              </div>
              <div className="boxA">
                <Setbox
                  title={translate.t?.the_coffee_lab_public}
                  heading={translate.t?.all_answers_you_need}
                  description={translate.t?.your_complete_source_renowned}
                  btnText={translate.t?.ask_your_question}
                  btnLink={`${process.env.GATSBY_SITE_URL}/coffee-lab/${
                    translate.currentLang === "sv"
                      ? "sv/fragor-och-svar"
                      : (translate.currentLang ? translate.currentLang : "en") +
                        "/qa-forum"
                  }`}
                  pageContext={{ translate }}
                />
                <img
                  src={coffeeLab}
                  alt="Coffee Lab"
                  className="coffeLabImage"
                />
              </div>
            </Container>
          </div>
          <div className="whiteBg logosection">
            <div className="setbox">
              <h4 className="text-uppercase">{translate.t?.estate_brands}</h4>
              <h5>{translate.t?.buy_coffee_from_estate}</h5>
              <p>{translate.t?.get_coffee_sourced_estates}</p>
            </div>
            <div className="logos">
              <img className="size1" src={logo1} alt="Logo 1" />
              <img className="size2" src={logo2} alt="Logo 2" />
              <img className="size3" src={logo3} alt="Logo 3" />
              <img className="size4" src={logo4} alt="Logo 4" />
              <img className="size5" src={logo5} alt="Logo 5" />
            </div>
          </div>
          <div className="greyBg finder-wrapper">
            <Container>
              <div className="boxA mobile-box">
                <Setbox
                  title={translate.t?.outlet_finder}
                  heading={translate.t?.find_eraofwe_store}
                  description={translate.t?.want_to_buy_local}
                  btnText={translate.t?.search_for_outlets}
                  btnLink={`${process.env.GATSBY_SITE_URL}/op/${
                    translate.currentLang === "sv" ? "kaffe" : "coffee"
                  }/`}
                  pageContext={{ translate }}
                />
                <img
                  src={findOutlet}
                  alt="Find Outlets"
                  className="outlerFinder"
                />
              </div>
            </Container>
          </div>
          <div className="whiteBg shop-wrapper">
            <Container>
              <div className="boxA shopSection">
                <Setbox
                  title={translate.t?.shop}
                  heading={translate.t?.buy_coffee_from_roasters}
                  description={translate.t?.get_coffee_sourced_roasters}
                  btnText={translate.t?.shop_coffee}
                  btnLink="https://www.lofbergs.se/kaffe-online/"
                  pageContext={{ translate }}
                />
                <img src={shop} alt="Shop" className="shop" />
              </div>
            </Container>
          </div>
        </div>
      }
    </>
  )
}

export default Offer
