import * as React from "react"
import Hero from "../roaster/hero"
import Offer from "./offer"
import postervideo from "../../assets/images/consumerBannerNew.png"
import Join from "../roaster/join"
import Trace from "./traceability"
import Origin from "../roaster/origin"

const Consumer = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="consumer_page">
          <div className="content">
            <div className="roaster_page">
              <Hero
                pageContext={{ translate }}
                pagename={translate.t?.eraofwe_for_consumers}
                title={translate.t?.sip_with_confidence_public}
                description={translate.t?.where_coffee_connect_people}
                btntext={translate.t?.visit_coffee_forum}
                img={postervideo}
                btnLink={`${process.env.GATSBY_SITE_URL}/coffee-lab/${
                  translate.currentLang === "sv"
                    ? "sv/fragor-och-svar"
                    : (translate.currentLang ? translate.currentLang : "en") +
                      "/qa-forum"
                }`}
              />
              <div className="consumer-origin">
                <Origin
                pageContext={{ translate }}
                  heading={translate.t?.show_others_c2c}
                  description={translate.t?.get_insights_land_lots}
                  btnText={translate.t?.how_it_works}
                  btnLink={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }traceability/`}
                />
              </div>
              <Offer pageContext={{ translate }} />
              <Trace pageContext={{ translate }} />
              <div className="joiner-second">
                <Join pageContext={{ translate }} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Consumer
