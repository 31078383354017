import * as React from "react";


const MicroBox = ({img, count, heading, description}) => {
  return (
  <>
    {
      (

    <div className="box">
      <img src={img} alt="Micro box" />
        <div className="heading">
          <div className="count">
            <span>{count}</span>
            <h4>{heading}</h4>
          </div>
          <p>
            {description}
          </p>
        </div>
    </div>
  )
    }
    </>
  )
}

export default MicroBox
