import * as React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { Row, Col } from "react-bootstrap"

import n1 from "../../assets/images/n1.jpg"
import n2 from "../../assets/images/n2.jpg"
import n3 from "../../assets/images/n3.jpg"
import n4 from "../../assets/images/n4.jpg"
import n5 from "../../assets/images/n5.jpg"
import slidesLineDesktop from "../../assets/images/slider-line.svg"
import slidesLine from "../../assets/images/sliderLineTablet.svg"
import slidesLineMobile from "../../assets/images/sliderLIneMobile.svg"

const Origin = ({
  heading,
  description,
  btnText,
  btnLink,
  pageContext: { translate },
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       slidesToShow: 1.8,
    //       slidesToScroll: 1.8,
    //       initialSlide: 0,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 0,
    //     },
    //   },
    // ],
  }
  const originData = [
    {
      img: n1,
      title: translate.t?.end_consumer,
      description: translate.t?.get_feedback_hqt,
      link: "",
    },
    {
      img: n2,
      title: translate.t?.estate,
      description: translate.t?.develop_direct_relationships,
      link: "",
    },
    {
      img: n3,
      title: translate.t?.facilitator,
      description: translate.t?.get_data_logistics,
      link: "",
    },
    {
      img: n4,
      title: translate.t?.roaster,
      description: translate.t?.with_gcm_source_offerings,
      link: "",
    },
    {
      img: n5,
      title: translate.t?.hospitality,
      description: translate.t?.work_with_equitable,
      link: "",
    },
  ]

  return (
    <>
      {
        <>
          <div className="origin desktop">
            <div className="transparentBG">
              <div className="content">
                <div className="detail">
                  <div className="left">
                    <h2 className="heading-h2 semi white">{heading}</h2>
                    <p className="paragraph-p1 white">{description}</p>
                    <Link to={btnLink}>
                      <button className="btndefault">{btnText}</button>
                    </Link>
                  </div>
                  <div className="right">
                    <div className="img-line-box">
                      <div className="box mb-0">
                        <img src={originData[0].img} alt="Origin" />
                        <div className="info">
                          <h3 className="heading-h2 semi white">
                            {originData[0].title}
                          </h3>
                          <p className="paragraph-p16 white">
                            {originData[0].description}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row className="mx-4">
                  <Col sm={1}></Col>
                  <Col sm={11}>
                    <img src={slidesLineDesktop} alt="SliderLineDesktop" />
                  </Col>
                </Row>
                <div className="new-block">
                  <div className="flex-line-box">
                    {originData?.map((x, counter) => {
                      return (
                        counter !== 0 && (
                          <div key={counter} className="box-wrapper">
                            <div className="box">
                              <img src={x.img} alt="Origin data" />
                              <div className="info">
                                <h3 className="heading-h2 semi white">
                                  {x.title}
                                </h3>
                                <p className="paragraph-p16 white">
                                  {x.description}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="origin tablet">
            <div className="transparentBG">
              <div className="content">
                <div className="detail">
                  <div className="left">
                    <h2 className="heading-h2 semi white">{heading}</h2>
                    <p className="paragraph-p1 white">{description}</p>
                    <Link to={btnLink}>
                      <button className="btndefault">{btnText}</button>
                    </Link>
                  </div>
                </div>
                <div className="new-block">
                  <Row className="sliderLine">
                    <Col sm={4}>
                      <img src={slidesLine} alt="Origin slider" />
                    </Col>
                  </Row>
                  <Row className="flex-md-nowrap">
                    <div className="z-index-1">
                      {originData?.map((x, counter) => {
                        return (
                          counter === 0 && (
                            <div key={counter} className="img-line-box">
                              <div className="box">
                                <div className="imgBox">
                                  <img src={x.img} alt="LineImage" />
                                </div>
                                <div className="info">
                                  <h3 className="heading-h2 semi white">
                                    {x.title}
                                  </h3>
                                  <p className="paragraph-p16 white">
                                    {x.description}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      })}
                    </div>
                    <Col xs={12} className="sliderLIneMobile">
                      <img src={slidesLineMobile} alt="SliderLineMobile" />
                    </Col>
                    <div className="sliderSection">
                      <Slider {...settings}>
                        {originData?.map((x, counter) => {
                          return (
                            counter !== 0 && (
                              <div key={counter} className="box h-100">
                                <div className="imgBox">
                                  <img src={x.img} alt="Origin data" />
                                </div>
                                <div className="info">
                                  <h3 className="heading-h2 semi white">
                                    {x.title}
                                  </h3>
                                  <p className="paragraph-p16 white">
                                    {x.description}{" "}
                                  </p>
                                </div>
                              </div>
                            )
                          )
                        })}
                      </Slider>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Origin
