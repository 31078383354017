import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import ConsumerPage from "../components/consumer"
import languages from "../utils/enums/languages"

const Consumer = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    path="partners"
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.sip_with_confidence_public}
        description={data.t?.consumer_metad}
        keywords={data.t?.keywords_consumer}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }consumer/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/consumer/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <ConsumerPage pageContext={{ translate }} />
        </>
      }
    </>
  </Layout>
)

export default Consumer
